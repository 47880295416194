import React from "react";
import PropTypes from "prop-types";

const BlogHero = ({ subtitle, title }) => {
  return (
    <div className="blogs">
      <div className="content needs-c">
        <div className="left-right-blog">
          <div className="left-right-half">
            <p className="cyan-txt">{subtitle}</p>
            <h4 className="blogs-heading pre-line">{title}</h4>
          </div>
          <div className="left-right-half"></div>
        </div>
      </div>
    </div>
  );
};

BlogHero.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

BlogHero.defaultProps = {
  subtitle: "Tips and Tricks",
  title: "Read our tips and \nguides to help you \nget ahead",
};

export default BlogHero;
