import * as React from "react";

import Layout from "../../components/Layout";
import BlogHero from "../../components/BlogHero";
import BlogRoll from "../../components/BlogRoll";
import HomeCta from "../../components/HomeCta";
export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout noPadding isWhite={true} darkLogo={true}>
        <BlogHero />
        <section className="section">
          <div className="container">
            <div className="content">
              <BlogRoll />
            </div>
          </div>
        </section>
        <HomeCta />
      </Layout>
    );
  }
}
