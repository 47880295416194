import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faCalendarDays } from "@fortawesome/free-regular-svg-icons";

import PreviewCompatibleImage from "./PreviewCompatibleImage";
import Tag from "./Tag";

const BlogCard = ({ categories, title, createdAt, readTime, slug, img }) => {
  return (
    <div className="blog-card">
      <div className="thumbnail">
        {img && (
          <PreviewCompatibleImage
            imageInfo={{
              image: img,
              alt: title,
              width: img?.childImageSharp?.gatsbyImageData?.width,
              height: img?.childImageSharp?.gatsbyImageData?.height,
            }}
          />
        )}
      </div>
      <div className="blog-item">
        <div className="blog-header">
          {categories && (
            <div className="categories">
              {categories.map((category, index) => (
                <Tag key={`tag-${index}`} variant="blue" label={category} />
              ))}
            </div>
          )}
          <Link to={slug}>
            <h1>{title}</h1>
          </Link>
        </div>
        <div className="blog-footer">
          <div>
            <FontAwesomeIcon icon={faCalendarDays} />
            <span>{createdAt}</span>
          </div>
          <div>
            <FontAwesomeIcon icon={faClock} />
            <span>{`${readTime} min read`}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

BlogCard.propTypes = {
  categories: PropTypes.array,
  title: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  readTime: PropTypes.number,
  slug: PropTypes.string.isRequired,
  img: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

BlogCard.defaultProps = {
  categories: [],
  readTime: 10,
  img: null,
};

export default BlogCard;
