import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faCalendarDays } from "@fortawesome/free-regular-svg-icons";

import PreviewCompatibleImage from "./PreviewCompatibleImage";
import Tag from "./Tag";

const BlogFeatured = ({
  categories,
  title,
  desc,
  createdAt,
  readTime,
  slug,
  img,
}) => {
  return (
    <div className="blog-featured">
      <div className="thumbnail">
        {img && (
          <PreviewCompatibleImage
            imageInfo={{
              image: img,
              alt: title,
              width: img.childImageSharp.gatsbyImageData.width,
              height: img.childImageSharp.gatsbyImageData.height,
            }}
          />
        )}
      </div>
      <div className="blog-item">
        <div className="blog-header">
          <div className="blog-info">
            {categories && (
              <div className="blog-info-left categories">
                {categories.map((category, index) => (
                  <Tag key={`tag-${index}`} variant="blue" label={category} />
                ))}
              </div>
            )}
            <div className="blog-info-right">
              <div>
                <FontAwesomeIcon icon={faCalendarDays} />
                <span>{createdAt}</span>
              </div>
              <div>
                <FontAwesomeIcon icon={faClock} />
                <span>{`${readTime} min read`}</span>
              </div>
            </div>
          </div>
          <Link to={slug}>
            <h1>{title}</h1>
          </Link>
          <p>{desc}</p>
          <Link to={slug} className="cta cta-mirror long w-button">
            Read more
          </Link>
        </div>
      </div>
    </div>
  );
};

BlogFeatured.propTypes = {
  categories: PropTypes.array,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  readTime: PropTypes.number,
  slug: PropTypes.string.isRequired,
  img: PropTypes.object,
};

BlogFeatured.defaultProps = {
  categories: [],
  readTime: 10,
  img: null,
};

export default BlogFeatured;
