import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from "gatsby";

import BlogCard from "./BlogCard";
import BlogFeatured from "./BlogFeatured";
import readingTime from "../utils/readingTime";

class BlogRollTemplate extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.normal;
    const mostRecentPost = data?.featured?.edges[0];

    return (
      <div className="blog-container">
        {mostRecentPost && (
          <BlogFeatured
            title={mostRecentPost.node.frontmatter.title}
            desc={mostRecentPost.node.excerpt}
            createdAt={mostRecentPost.node.frontmatter.date}
            readTime={readingTime(mostRecentPost.node.html)}
            slug={mostRecentPost.node.fields.slug}
            img={mostRecentPost.node.frontmatter.featuredimage}
          />
        )}
        <div className="blog-grid-container">
          {posts &&
            posts.map(({ node: post }) => (
              <BlogCard
                key={post.id}
                title={post.frontmatter.title}
                createdAt={post.frontmatter.date}
                readTime={readingTime(post.html)}
                slug={post.fields.slug}
                img={post.frontmatter.featuredimage}
              />
            ))}
        </div>
      </div>
    );
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default function BlogRoll() {
  return (
    <StaticQuery
      query={graphql`
        query BlogRollQuery {
          featured: allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
            limit: 1
          ) {
            edges {
              node {
                excerpt(pruneLength: 200)
                html
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  templateKey
                  date(formatString: "MMM DD, YYYY")
                  featuredpost
                  featuredimage {
                    childImageSharp {
                      gatsbyImageData(
                        width: 360
                        quality: 100
                        layout: CONSTRAINED
                      )
                    }
                  }
                  tags
                }
              }
            }
          }
          normal: allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
            skip: 1
          ) {
            edges {
              node {
                excerpt(pruneLength: 200)
                html
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  templateKey
                  date(formatString: "MMM DD, YYYY")
                  featuredpost
                  featuredimage {
                    childImageSharp {
                      gatsbyImageData(
                        width: 360
                        quality: 100
                        layout: CONSTRAINED
                      )
                    }
                  }
                  tags
                }
              }
            }
          }
        }
      `}
      render={(data, count) => <BlogRollTemplate data={data} count={count} />}
    />
  );
}
